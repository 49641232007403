/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Clean document of text nodes
        function clean(node)
          {
            for(var n = 0; n < node.childNodes.length; n ++)
            {
              var child = node.childNodes[n];
              if
              (
                child.nodeType === 8 || (child.nodeType === 3 && !/\S/.test(child.nodeValue))
              )
              {
                node.removeChild(child);
                n --;
              }
              else if(child.nodeType === 1)
              {
                clean(child);
              }
            }
          }
        clean(document);

        // matchHeight
        $(function() {
          $('article.post').matchHeight();
          $('article.post .entry-title').matchHeight();
          $('article.post .excerpt').matchHeight();
          $('.service').matchHeight();
        });

        // AJAX blog posts
        $(document).ajaxComplete(function(){
          $('article.post').matchHeight();
          $('article.post .entry-title').matchHeight();
          $('article.post .excerpt').matchHeight();
        });

        // Slick slider
        $(document).ready(function(){
          $('.slick').slick({
            autoplay: true,
            autoplaySpeed: 7500,
            arrows: false,
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1
          });
        });

        // Mobile Toggle
        $('.mobile-btn').click(function(){
          $('.nav-primary').toggleClass('right-reset');
        });

        // Scroll to element
        jQuery(document).ready(function() {  
          jQuery('.nav-primary a[href^="#"]').click(function() {  
            var target = jQuery(this.hash);  
            if (target.length === 0) { target = jQuery('div[id="' + this.hash.substr(1) + '"]');} 
            jQuery('html, body').animate({ scrollTop: target.offset().top - 0 }, 500);  
            return false; 
          });  
        });

        // Scroll to top when clikcing 'Home'
        jQuery(document).ready(function() {  
          jQuery('.nav-primary li:first-of-type a').click(function() {  
            jQuery('html, body').animate({ scrollTop: 0 }, 500);  
            return false; 
          });  
        });

        // Home header scroll button
        jQuery(document).ready(function() {  
          jQuery('.home-header .scroll-to').click(function() {  
            jQuery('html,body').animate({scrollTop: $('#approach').offset().top}, '500');  
            return false; 
          });  
        });

        // Scroll to new posts after AJAX
        /*$(document).ajaxComplete(function(){
          $('.alm-load-more-btn').each(function(){
            $(this).click(function(){ 
              $('html,body').animate({scrollTop: $('.alm-reveal:last-of-type').offset().top -100 }, 'slow');
            return false; 
            });
          });
        });*/

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
        // Stop dummy links effecting url
        $('a[href^="#"]').click(function(e){
          e.preventDefault();
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
